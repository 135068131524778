<template>
  <el-form
    :label-position="'left'"
    label-width="80px"
    @submit.prevent="onSubmit"
    style="margin: 20px; width: 60%; min-width: 600px"
  >
    <el-form-item label="练习名称">
      <el-input v-model="requestContent.practiceName"></el-input>
    </el-form-item>
    <el-form-item label="练习描述">
      <aidaFeedEditor
        :openMd="true"
        :openHtml="true"
        :openCos="true"
        :openVod="false"
        :openPractice="false"
        v-model="requestContent.choiceAsset.description"
      ></aidaFeedEditor>
    </el-form-item>

    <el-form-item label="选项个数">
      <el-radio-group v-model="optionCnt" size="small">
        <el-radio-button label="2">2选项</el-radio-button>
        <el-radio-button label="3">3选项</el-radio-button>
        <el-radio-button label="4">4选项</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="选项">
      <el-form-item v-for="(item, index) in requestContent.choiceAsset.choice" :key="index" :label="index | capitalize">
        <el-input v-model="requestContent.choiceAsset.choice[index]" class="choice-item"></el-input>
      </el-form-item>
    </el-form-item>
    <el-form-item label="正确选项">
      <el-input placeholder="0,1" v-model="choice.answer"></el-input>
    </el-form-item>
    <el-form-item label="解析">
      <aidaFeedEditor
        :openMd="true"
        :openHtml="true"
        :openCos="true"
        :openVod="false"
        :openPractice="false"
        v-model="requestContent.choiceAsset.analysis"
      ></aidaFeedEditor>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onAddChoiceAsset">{{ requestBtnText }}</el-button>
      <el-button>取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { MarkdownFileType } from "@/util/EnumUtil";
import aidaFeedEditor from "@/component/aidaFeedEditor";
import { alertMessage } from "@/util/alertMessageUtil.js";
import { setTimeout } from "timers";
import { practiceApi } from "@/api/practiceApi";

export default {
  components: {
    aidaFeedEditor,
  },
  data() {
    return {
      cosRet: {},
      dialogVisible: false,
      optionCnt: 2,
      choice: {
        type: 2,
        description: "",
        choice: ["aa", "bb"],
        answer: null,
        analysis: "",
      },
      requestStatus: true,

      requestContent: {
        choiceAsset: {
          analysis: [
            {
              unitType: MarkdownFileType.MARKDOWN,
              unitId: 0,
              index: 0,
              content: "",
              fileName: "",
              url: "",
              markDown: {
                content: "",
              },
            },
          ],
          answer: [0],
          choice: ["aa", "bb"],
          choiceType: 2,
          description: [
            {
              unitType: MarkdownFileType.MARKDOWN,
              unitId: 0,
              index: 0,
              content: "",
              fileName: "",
              url: "",
              markDown: {
                content: "",
              },
            },
          ],
        },
        practiceId: 0,
        practiceName: "",
      },
      requestBtnText: "立即创建",
    };
  },
  methods: {
    onAddChoiceAsset() {
      setTimeout(() => {
        this.requestStatus = true;
        let alertText = "请填写练习名";
        let alertType = "warning";

        if (!this.requestContent.practiceName) {
          alertMessage(alertText, alertType);
          return;
        }
        alertText = "请完善练习描述";

        this.requestContent.choiceAsset.description.map((item) => {
          if (!item.content) {
            alertMessage(alertText, alertType);
            this.requestStatus = false;
          }
        });
        alertText = "请完善正确选项";
        if (!this.choice.answer) {
          alertMessage(alertText, alertType);
          this.requestStatus = false;
          return;
        }
        let answer = this.choice.answer.split(",");
        let intAnswerList = answer.map((item) => {
          item = parseInt(item);
          return item;
        });
        this.requestContent.choiceAsset.answer = intAnswerList;
        alertText = "请完善解析";

        this.requestContent.choiceAsset.analysis.map((item) => {
          if (!item.content) {
            alertMessage(alertText, alertType);
            this.requestStatus = false;
          }
        });

        if (this.requestStatus) {
          practiceApi.choiceAsset(this.requestContent).then((data) => {
            if (data.code === 0) {
              if (this.requestBtnText == "立即创建") {
                alertMessage("创建成功", "success");
              } else {
                alertMessage("修改成功", "success");
              }
            } else {
              alertMessage(data.msg, "warning");
            }
          });
        }
      }, 200);
    },
    onSubmit() {
      /**
       * markdown获取组件值，由于markdownEitor是通过div的onchange事件给对应的editor赋值
       * 所以微了确保能赋值进去，需要延迟一点，后在执行接下来操作,最后做提交的时候应注意这点
       */
      setTimeout(() => {});
    },
    markdownChange() {},
  },
  mounted() {
    if (this.$route.params.id) {
      this.requestBtnText = "立即修改";
      practiceApi.getChoicePracticeById(this.$route.params.id).then((ret) => {
        if (ret.code === 0) {
          console.log(ret.data);
          let newData = {
            choiceAsset: ret.data.choiceAssetVO,
            practiceId: ret.data.practiceId,
            practiceName: ret.data.practiceName,
          };
          let responseAnswer = "";
          for (let i = 0; i < ret.data.choiceAssetVO.answer.length; i++) {
            if (i < ret.data.choiceAssetVO.answer.length - 1) {
              responseAnswer += ret.data.choiceAssetVO.answer[i] + ",";
            } else {
              responseAnswer += ret.data.choiceAssetVO.answer[i];
            }
          }
          this.optionCnt = ret.data.choiceAssetVO.choiceType;
          this.choice.answer = responseAnswer;
          this.requestContent = newData;
        } else {
          alertMessage("获取数据失败，请稍后再试", "warning");
        }
      });
    }
  },
  filters: {
    capitalize: function(val) {
      //return String.fromCharCode(97 + val)
      return val.toString();
    },
  },
  watch: {
    optionCnt: function(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.requestContent.choiceAsset.choiceType = parseInt(newVal);
      if (this.requestContent.choiceAsset.choice.length < this.requestContent.choiceAsset.choiceType) {
        for (
          let i = this.requestContent.choiceAsset.choice.length;
          i < this.requestContent.choiceAsset.choiceType;
          i++
        ) {
          this.requestContent.choiceAsset.choice.push("");
        }
      } else if (this.requestContent.choiceAsset.choice.length > this.requestContent.choiceAsset.choiceType) {
        for (
          let i = this.requestContent.choiceAsset.choice.length - 1;
          i > this.requestContent.choiceAsset.choiceType - 1;
          i--
        ) {
          this.requestContent.choiceAsset.choice.splice(i);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.choice-item {
  margin-bottom: 5px;
}
</style>
